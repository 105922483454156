import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import { ClipboardIcon, CheckIcon, DocumentTextIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { nanoid } from 'nanoid';
import localStorageSlim from 'localstorage-slim';
import env from '../config/env';
import FormatText from './FormatText';
import { marked } from 'marked';
import USAStateList from '../config/USA_State_List.json';
import style from './multipleDocSummary.module.css'

const apiCallWithAuth = async (options) => {
  try {
    const token = localStorageSlim.get('auth', { decrypt: true });
    if (!token) throw new Error('No auth token found');

    const response = await axios({
      ...options,
      headers: {
        ...options.headers,
        'authToken': token,
      }
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      const newAuthToken = await fetchAuthToken();
      const response = await axios({
        ...options,
        headers: {
          ...options.headers,
          'authToken': newAuthToken,
        }
      });
      return response;
    } else {
      throw error;
    }
  }
};

const fetchAuthToken = async () => {
  try {
    const response = await axios.get('/api/auth');
    const authToken = response.data.authToken;
    localStorageSlim.set('auth', authToken, { encrypt: true, ttl: 3600 });
    return authToken;
  } catch (error) {
    console.error('Error getting auth token:', error);
    throw new Error('Failed to get auth token');
  }
};

const MultipleDocSummary = () => {
  const maxPageLimitForDoc = 50
  const [files, setFiles] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [summarizeLoading, setSummarizeLoading] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showShareEmailButton, setShowShareEmailButton] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showMailPopup, setShowMailPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [sendingMail, setSendingMail] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState('');
  const [docLinks, setDocLinks] = useState([]);
  const [requestId, setRequestId] = useState('');
  const formattedContentRef = useRef(null);
  const [showButtons, setShowButtons] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    const initializeAuthToken = async () => {
      try {
        const token = localStorageSlim.get('auth', { decrypt: true });
        if (!token) {
          await fetchAuthToken();
        }
      } catch (error) {
        console.error('Error initializing auth token:', error);
      }
    };

    const initializeUserId = () => {
      let userId = localStorageSlim.get('userId', { decrypt: true });
      if (!userId) {
        userId = nanoid(20);
        localStorageSlim.set('userId', userId, { encrypt: true });
      }
    };

    initializeAuthToken();
    initializeUserId();

    // // Load state options from JSON file
    // const loadStates = async () => {
    //   try {
    //     const response = await axios.get('/path/to/array.json'); // Adjust the path to your JSON file
    //     setStates(response.data);
    //   } catch (error) {
    //     console.error('Error loading states:', error);
    //   }
    // };
    
    // loadStates();
    setStates(USAStateList);
    let state =  localStorage.getItem("state")
    if (state) setSelectedState(state)
  }, []);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 3) {
      setErrorMessages(['You can only upload up to 3 files.']);
      return;
    }
    if (selectedFiles.every(file => file.type === 'application/pdf')) {
      try {
        const pageChecks = await Promise.all(selectedFiles.map(async (file) => {
          const arrayBuffer = await file.arrayBuffer();
          const pdf = await PDFDocument.load(arrayBuffer);
          if (pdf.getPageCount() > maxPageLimitForDoc) {
            throw new Error(`The document ${file.name} has more than ${maxPageLimitForDoc} pages, kindly check and upload only required information.`);
          }
        }));
        setFiles(selectedFiles);
        setSummarizeLoading(new Array(selectedFiles.length).fill(false));
        setErrorMessages(new Array(selectedFiles.length).fill(''));
      } catch (error) {
        setErrorMessages([error.message]);
        setFiles([]);
      }
    } else {
      setErrorMessages(['Please select valid PDF files.']);
    }
  };

  const handleUploadAndSummarize = async () => {
    if (!selectedState) {
      setErrorMessages(['Please select a state.']);
      return;
    }

    if (files.length === 0) return;
    setUploadLoading(true);
    setErrorMessages([]);
    setSummaries(new Array(files.length).fill({ fileName: '', text: '' }));
    setDocLinks([]);

    const newRequestId = nanoid(20);
    setRequestId(newRequestId);

    try {
      const userId = localStorageSlim.get('userId', { decrypt: true });
      const token = localStorageSlim.get('auth', { decrypt: true });
      if (!token) throw new Error('No auth token found');

      const uploadPromises = files.map(file => {
        const formData = new FormData();
        formData.append('file', file);
        return apiCallWithAuth({
          method: 'POST',
          url: '/api/upload',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }).catch(error => {
          throw new Error(`Failed to upload file: ${file.name}`);
        });
      });

      const uploadResponses = await Promise.all(uploadPromises);
      const filePaths = uploadResponses.map(response => response.data.filePath);
      setDocLinks(filePaths);

      const summaryPromises = filePaths.map((filePath, index) => {
        setSummarizeLoading(prevLoading => {
          const newLoading = [...prevLoading];
          newLoading[index] = true;
          return newLoading;
        });
        return fetch(`${env.domainUrl}/ms-doc-summary/stream/demo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authToken': token,
          },
          body: JSON.stringify({
            fileLink: filePath,
            requestId: newRequestId,
            userId,
            state: selectedState,
            parentRequestId: ""
          })
        })
          .then(response => handleStreamResponse(response, index, files[index].name))
      });
      await Promise.all(summaryPromises);

      setUploadLoading(false);
      setShowShareEmailButton(true);
    } catch (error) {
      console.error('Error uploading or summarizing file:', error);
      setErrorMessages(['Failed to upload or summarize file. Please try again.']);
      setSummarizeLoading(new Array(files.length).fill(false));
      setUploadLoading(false);
    }
  };

  const handleStreamResponse = async (response, index, fileName) => {
    if (response.status === 403) {
      await fetchAuthToken();
      return handleUploadAndSummarize();
    } else if (response.status == 200) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let summaryText = '';

      if (response.status == 200) {
        setUploadLoading(false);
        setSummarizeLoading(prevLoading => {
          const newLoading = [...prevLoading];
          newLoading[index] = false;
          return newLoading;
        });
        setShowButtons(true);
        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;
          summaryText += decoder.decode(value);
          setSummaries(prevSummaries => {
            const newSummaries = [...prevSummaries];
            newSummaries[index] = { fileName, text: summaryText };
            return newSummaries;
          });
        }

      }
    } else {
      console.error(`Error summarizing file ${files[index].name}:`, response);
      setUploadLoading(false);
      setErrorMessages(prevErrors => {
        const newErrors = [...prevErrors];
        newErrors[index] = `Failed to summarize file: ${files[index].name}`;
        return newErrors;
      });
      setSummarizeLoading(prevLoading => {
        const newLoading = [...prevLoading];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const copyToClipboard = () => {
    const text = formattedContentRef.current.innerText;
    navigator.clipboard.writeText(text);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const resetExperience = () => {
    setFiles([]);
    setSummaries([]);
    setSummarizeLoading([]);
    setShowButtons(false);
    setErrorMessages([]);
    setSelectedState('');
  };

  const handleSendMail = async () => {
    if (!email || !validateEmail(email)) {
      setPopupErrorMessage('Please enter a valid email address.');
      return;
    }
    setSendingMail(true);

    const accumulatedText = summaries
      .map((summary, index) => summary && `**Summary of ${summary.fileName}**\n\n${summary.text}`)
      .join('\n\n\n\n');

    let summaryHtmlContent = marked(accumulatedText);
    const docLink = docLinks.join(',');
    summaryHtmlContent = summaryHtmlContent.replace(/\$/g, '&#36;');

    try {
      await apiCallWithAuth({
        method: 'POST',
        url: '/api/sendmail',
        data: {
          email,
          summaryHtmlContent,
          docLink,
          requestId,
          userId: localStorageSlim.get('userId', { decrypt: true })
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setMailSent(true);
    } catch (error) {
      console.error('Error sending email:', error);
      setPopupErrorMessage('Failed to send email. Please try again.');
    }

    setSendingMail(false);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const openMailPopup = () => {
    setShowMailPopup(true);
    setEmail('');
    setMailSent(false);
    setPopupErrorMessage('');
  };

  return (
    <div className="w-full max-w-5xl mx-auto rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4 text-center">

        {(
          <>
            <div className="mb-10">
              <select
                value={selectedState}
                onChange={(e) => {setSelectedState(e.target.value); setErrorMessages([]); localStorage.setItem("state",e.target.value)}}
                className={`w-72 p-2 py-3 border border-gray-300 rounded text-black dark:bg-gray-700 dark:border-gray dark:text-white cursor-pointer ${style.selectWrapper}`}
              >
                <option value="" disabled>Select State</option>
                {states.map((state) => (
                  <option key={state.code} value={state.code}>{state.name}</option>
                ))}
              </select>
            </div>
          </>
        )}

        {files.length === 0 ? (
          <>
            <label
              htmlFor="fileInput"
              className="flex items-center justify-center bg-green text-white font-bold py-3 px-6 rounded cursor-pointer mx-auto text-lg w-72"
            >
              <DocumentTextIcon className="h-6 w-6 mr-2" />
              Upload PDFs
            </label>
            <input
              id="fileInput"
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
              className="hidden"
              multiple
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center font-bold mb-11 dark:text-white text-black space-y-2">
            <span className="mr-2">Selected:</span>
            {files.map((file, index) => (
              <div key={index} className="flex items-center">
                <DocumentIcon className="h-6 w-6 text-green mr-2" />
                {file.name}
              </div>
            ))}
          </div>
        )}

        {files.length > 0 && !uploadLoading && !showButtons && (
          <>
            <button
              onClick={handleUploadAndSummarize}
              className="mt-10 flex items-center justify-center mx-auto text-white font-bold py-3 px-6 rounded text-lg bg-green hover:bg-indigo-700"
            >
              Generate Summary
            </button>
          </>
        )}
      </div>

      {summaries.length === 0 && errorMessages.map((message, index) => (
        <div key={index} className="mb-4 text-red text-center">
          {message}
        </div>
      ))}

      {showButtons && (
        <div className="flex flex-col md:flex-row justify-center space-x-0 md:space-x-4 mt-4 mb-4 space-y-4 md:space-y-0">
          <button
            onClick={copyToClipboard}
            className="flex items-center justify-center bg-green text-white font-bold py-2 px-4 rounded cursor-pointer"
          >
            {showNotification ? <CheckIcon className="h-6 w-6 mr-2" /> : <ClipboardIcon className="h-6 w-6 mr-2" />}
            {showNotification ? 'Copied!' : 'Copy All'}
          </button>
          <button
            onClick={openMailPopup}
            disabled={!showShareEmailButton}
            className={`flex items-center justify-center bg-green text-white font-bold py-2 px-4 rounded cursor-pointer ${showShareEmailButton ? '' : 'bg-gray cursor-wait'}`}
          >
            Share on Email
          </button>
          <button
            onClick={resetExperience}
            className="flex items-center justify-center bg-green text-white font-bold py-2 px-4 rounded cursor-pointer"
          >
            Upload Another File
          </button>
        </div>
      )}

      <div ref={formattedContentRef}>
        {summaries.map((summary, index) => (
          <div key={index} className="bg-lightGray p-4 rounded mt-5 relative max-h-[800px] w-full overflow-auto mb-4">
            {summarizeLoading[index] || uploadLoading ? (
              <div className="flex flex-col items-center justify-center mt-4">
                <h3 className="text-lg font-bold mb-2 text-black">Summary of {files[index].name}</h3>
                <svg className="animate-spin h-20 w-20 text-green" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                <p className="font-bold mt-4 text-lg text-green">Generating Summary...</p>
              </div>
            ) : errorMessages[index] ? (
              <div className="text-red text-center">
                <p className="font-bold mt-4 text-lg">{errorMessages[index]}</p>
              </div>
            ) : (
              <>
                <h3 className="text-lg font-bold mb-2 text-black">Summary of {summary.fileName}</h3>
                <div className="text-black">
                  <FormatText content={summary.text} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <div className="text-center text-gray mt-16 dark:text-lightWhite">
        <p>Files stay private. Automatically deleted after 2 hours.</p>
        <p>Free service for documents up to {maxPageLimitForDoc} pages or 10 MB and 3 tasks per hour.</p>
        <p className='mt-4'>
          <a href="https://rebillion.ai/terms-of-service/" className="underline">Terms of Use</a> and <a href="https://rebillion.ai/privacy-policy/" className="underline">Privacy Policy</a>
        </p>
      </div>

      {showMailPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-96">
            {!mailSent ? (
              <>
                <h2 className="text-lg font-medium mb-4 text-black2 text-left">Share the Summary on Email</h2>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  className="w-full p-2 mb-4 border border-gray-300 rounded bg-white text-black"
                />
                {popupErrorMessage && (
                  <div className="mb-4 text-red text-center">
                    {popupErrorMessage}
                  </div>
                )}
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={() => setShowMailPopup(false)}
                    className="border-2 border-lightGray2 text-black2 font-medium py-2 px-4 rounded"
                  >
                    Close
                  </button>
                  <button
                    onClick={handleSendMail}
                    disabled={sendingMail}
                    className={`bg-green hover:bg-indigo-700 text-white font-medium py-2 px-4 rounded ${sendingMail ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {sendingMail ? (
                      <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                    ) : (
                      'Share'
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="text-green font-bold mb-4">Summary sent successfully!</p>
                <button
                  onClick={() => setShowMailPopup(false)}
                  className="border-2 border-lightGray2 text-black2 font-medium py-2 px-4 rounded"
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultipleDocSummary;
