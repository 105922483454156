import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import styles from './FormatText.module.css';

const FormatText = ({ content }) => {
  const createMarkup = (markdown) => {
    // Clean up the content by removing excessive newlines and trim spaces
    const cleanedContent = markdown.replace(/\n\s*\n\s*\n/g, '\n\n').trim();
    const htmlContent = marked(cleanedContent);
    // console.log({
    //     textContent: content,
    //     htmlContent: htmlContent,
    // })
    return { __html: htmlContent };
  };

  return (
    <div className={styles.container} dangerouslySetInnerHTML={createMarkup(content)} />
  );
};

export default FormatText;
